@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap");

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.social {
  fill:"red"
}

.slick-list {
  margin: 0 -22px !important;
}

.slick-slide > div {
  padding: 0 22px !important;
}

.circle {
  padding: 28px 48px;
  background:#AF1E1E;
  text-align: center;
  border-radius: 50%;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #FFFFFF;
  font-family: 'Yeseva One';
}

@media screen and (max-width: 900px)
{
  .circle {
    padding: 14px 24px;
  }
}

@media screen and (max-width: 600px)
{
  .circle {
    padding: 8px 18px;
  }
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

html, body { 
  scroll-behavior: smooth; 
}
